@import "./node_modules/foundation-sites/scss/util/util";

// 1. Global
// ---------

$global-font-size: 87.5%;
$global-width: rem-calc(1200);
$global-lineheight: 1.4;
$foundation-palette: (
  primary: color(primary),
  secondary: color(secondary),
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
);
$light-gray: color(grey, light);
$medium-gray: color(grey, medium);
$dark-gray: color(grey, dark);
$black: color(black);
$white: color(white);
$body-background: color(white);
$body-font-color: $dark-gray;
$body-font-family: $font-family-default;
$body-antialiased: true;
$global-margin: rem-calc(30);
$global-padding: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: rem-calc(8);
$global-text-direction: ltr;
$global-flexbox: false;
$print-transparent-backgrounds: true;

@include add-foundation-colors;

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
$print-breakpoint: large;
$breakpoint-classes: (small medium large);

// 3. The Grid
// -----------

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 30px,
);
$grid-column-align-edge: true;
$block-grid-max: 8;

// 4. Base Typography
// ------------------

$header-font-family: $font-family-alt;
$header-font-weight: $global-weight-normal;
$header-font-style: normal;
$font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;
$header-color: inherit;
$header-small-font-color: $header-color;
$header-lineheight: 1.4;
$header-margin-bottom: 0.5rem;
$header-styles: (
  small: (
    "h1": (
      "font-size": 24,
    ),
    "h2": (
      "font-size": 20,
    ),
    "h3": (
      "font-size": 19,
    ),
    "h4": (
      "font-size": 18,
    ),
    "h5": (
      "font-size": 17,
    ),
    "h6": (
      "font-size": 16,
    ),
  ),
  medium: (
    "h1": (
      "font-size": 48,
    ),
    "h2": (
      "font-size": 40,
    ),
    "h3": (
      "font-size": 31,
    ),
    "h4": (
      "font-size": 25,
    ),
    "h5": (
      "font-size": 20,
    ),
    "h6": (
      "font-size": 16,
    ),
  ),
);
$blockquote-color: inherit;
$blockquote-border: none;
$blockquote-padding: rem-calc(50) rem-calc(50) rem-calc(10) rem-calc(50);
$blockquote-fontsize: 1.6em;
$paragraph-lineheight: 1.4;
$stat-font-size: rem-calc(34);

// Header
$mainheader-size: rem-calc(70);
$mainheader-logo-height: rem-calc(60);

// Transition
$global-transition-duration: 250ms;
$global-transition-function: cubic-bezier(0.8, 0.2, 0.2, 1);

// Section
$section-default-padding: rem-calc(100);

$card-border: 1px solid color(background);
$card-shadow: none;
$card-padding: $global-padding;
$card-margin-bottom: 0;

// Labels
$label-color: color(grey, dark);
$label-radius: rem-calc(3);
