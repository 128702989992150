.ProfilForm {
  &-EmailAlreadyExists {
    color: color(alert);
    padding: $form-fieldset-padding-y $form-fieldset-padding-x;
    text-align: center;
    &,
    a {
      font-weight: bold;
    }
    a {
      color: color(primary);
      text-decoration: underline;
    }
  }
}
