.container {
  position: relative;
  @include grid-column-row;
}

.stat {
  @include font($stat-font-size, 1, normal, alt);
}

button {
  cursor: pointer;
}

::-ms-clear {
  display: none;
}

@mixin navbar($font-size: rem-calc(16), $font-weight: bold) {
  $link-padding: rem-calc(10);
  @extend %nobullet;
  margin-bottom: 0;
  margin-right: -$link-padding;

  li {
    display: inline-block;
    border-left: 1px solid currentColor;

    &:first-child {
      border: none;
    }
    a,
    button {
      color: inherit;
      display: block;
      padding: 0 $link-padding;
      @include font($font-size, 1.4, $font-weight);
    }
  }
}

@mixin panel($width) {
  background-color: color(white);
  box-shadow: 0px 1px 4px rgba(#000, 0.3);
  padding: rem-calc(40);

  @include breakpoint(medium) {
    width: $width;
    @include absolute-center;
  }
}
