$unsubscribe-card-size: rem-calc(569);
$checklist-offset: rem-calc(30);
$unsubscribe-logo-size: rem-calc(120);

.EmailUnsubscribe {
  background-color: color(secondary);
  height: 100vh;

  .lead {
    color: color(black);
    @include font(rem-calc(20), $font-weight: bold);
    @include breakpoint(small only) {
      @include font(rem-calc(16), rem-calc(18), $font-weight: bold);
    }
  }

  &-Wrapper {
    @include panel($unsubscribe-card-size);
  }

  &-Header {
    text-align: center;
    border-bottom: 1px solid color(grey, light);
    padding: $global-padding 0;
  }

  &-Logo {
    width: $unsubscribe-logo-size;
  }

  &-Content {
    margin: $global-padding 0;
  }

  &-Form {
    &-Field {
      @include form-field;
    }

    &-CheckList {
      @extend %nobullet;

      padding-left: $checklist-offset;

      &-Item {
        margin-bottom: $global-padding;
      }
    }

    &-Radio {
      position: relative;
      cursor: pointer;

      &-Input {
        @include element-invisible;
      }

      &-Box {
        left: -$checklist-offset;
        @include vertical-center;

        .active {
          opacity: 0;
        }
      }
    }

    &-Submit {
      @include button(
        false,
        color(primary),
        darken(color(primary), 10%),
        color(white),
        solid
      );

      &:disabled {
        opacity: 0.5;
      }

      @include breakpoint(small only) {
        //font-size: rem-calc(11);
        @include font(rem-calc(14), rem-calc(20), bold);
        padding-top: calc($global-padding / 2);
        padding-bottom: calc($global-padding / 2);
        @include button-expand;
      }
    }
  }
}
