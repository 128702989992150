$select-caret-size: rem-calc(6);
$select-caret-color: color(primary);
$select-options-background: color(white);
$select-padding-x: rem-calc(10);
$select-padding-y: rem-calc(20);
$select-tag-fontsize: rem-calc(12);
$select-tag-color: color(white);
$select-tag-background: color(grey, dark);
$select-tag-radius: rem-calc(4);
$select-tag-height: rem-calc(20);
$select-tag-width: rem-calc(200);
$select-tag-padding: rem-calc(5);

.JobAndLocationSelect {
  .form-label {
    @include element-invisible;
  }
}

.r-ss-trigger {
  cursor: pointer;
  overflow: hidden;
  // caret gutter
  padding-right: rem-calc(20);
  @include form-element($fixedHeight: false);

  &:before {
    @include css-triangle($select-caret-size, $select-caret-color, down);
    @include position(auto, rem-calc(4), rem-calc(10), auto);
  }
}

.r-ss-search-wrap {
  padding: $select-padding-y $select-padding-x;

  .r-ss-search-inner {
    border-bottom: $input-border-size solid $input-border-color;
    @include form-field(0);

    label {
      @include element-invisible;
    }

    input {
      @include form-element;
    }

    .r-ss-magnifier {
      @extend %material-icons;
      color: color(primary);
      right: 0;
      @include vertical-center;
    }
  }

  .r-ss-search-clear {
    display: none;
  }
}

.r-ss-dropdown {
  background: $select-options-background;
  box-shadow: 0 1px 3px rgba(color(black), 0.3);
  margin-bottom: $global-padding;
  width: 100%;
  z-index: 999;
  @include position($input-height, auto, auto, 0);
}

.r-ss-options-wrap {
  @extend %nobullet;
  max-height: 45vh;
  overflow-y: auto;
}

.r-ss-dropdown-options {
  @extend %nobullet;
}

.r-ss-dropdown-option {
  padding: $select-padding-x;
}

.r-ss-option-group-heading {
  border-top: solid 1px color(grey, light);
  color: color(black);
  padding: $select-padding-x;
  @include font($font-weight: bold);
}

.selectItem {
  cursor: pointer;
  outline: none;
  padding-left: rem-calc(35) - $select-padding-x;
  position: relative;

  .CheckBox {
    left: 0;
    @include vertical-center;

    &-Selected {
      fill: color(white);

      .r-ss-selected & {
        fill: color(primary);
      }
    }
  }
}

.r-ss-selected {
  outline: none;
}

.r-ss-tag {
  display: inline-block;
  height: $select-tag-height;
  margin-right: rem-calc(5);

  &-label {
    @extend %truncate;
    background: $select-tag-background;
    border-radius: $select-tag-radius 0 0 $select-tag-radius;
    color: $select-tag-color;
    display: block;
    float: left;
    height: $select-tag-height;
    max-width: rem-calc($select-tag-width);
    padding: 0 $select-tag-padding;
    vertical-align: top;
    @include font($select-tag-fontsize, $select-tag-height, bold);
  }

  &-remove {
    background: color(background);
    border: none;
    border-radius: 0 $select-tag-radius $select-tag-radius 0;
    color: color(white);
    cursor: pointer;
    display: inline-block;
    height: $select-tag-height;
    outline: none;
    vertical-align: top;
    width: $select-tag-height;

    > span {
      display: inline-block;
      height: $select-tag-height;
      position: relative;
      width: $select-tag-height;

      &:before {
        @extend %material-icons;
        content: 'close';
        display: inline-block;
        font-size: $select-tag-fontsize * 0.8;
        height: 100%;
        line-height: 2;
        width: 100%;

        @include absolute-center;
      }
    }
  }
}
