/* Common, default styles for the notification box */
$alert-size: rem-calc(350);
$alert-title-size: rem-calc(16);
$alert-title-height: rem-calc(24);
$alert-icon-size: rem-calc(24);
$icon-box-size: rem-calc(50);

.s-alert-box,
.s-alert-box * {
  box-sizing: border-box;
}

.s-alert-box {
  background: color(primary);
  box-shadow: 0 2px 3px color(grey, dark);
  max-width: $alert-size;
  padding-left: $icon-box-size;
  pointer-events: none;
  position: fixed;
  transition: top 0.4s, bottom 0.4s;
  z-index: 9999;

  &-inner {
    background: color(white);
    padding: $global-padding;
    @include font;

    strong {
      display: block;
      @include font($alert-title-size, $alert-title-height, bold);
    }
  }
}

.s-alert-icon {
  height: 100%;
  width: $icon-box-size;
  @include position(0, auto, 0, 0);

  .icon {
    color: color(white);
    @include icon($alert-icon-size);
    @include absolute-center;
  }
}

.s-alert-close {
  cursor: pointer;
  float: right;
  transition: transform $global-transition-duration $global-transition-function;

  .icon {
    @include icon($alert-title-size, $alert-title-height);
  }

  &:hover,
  &:focus {
    transform: scale(0.8) translateY(2px);
  }
}

.s-alert-box.s-alert-show {
  pointer-events: auto;
}

.s-alert-box a {
  color: inherit;
  font-weight: 700;
  opacity: 0.7;
}

.s-alert-box a:hover,
.s-alert-box a:focus {
  opacity: 1;
}

.s-alert-box p {
  margin: 0;
}

.s-alert-box.s-alert-show,
.s-alert-box.s-alert-visible {
  pointer-events: auto;
}

/* positions */

.s-alert-bottom-left {
  bottom: 30px;
  left: 30px;
  right: auto;
  top: auto;
}
.s-alert-top-left {
  bottom: auto;
  left: 30px;
  right: auto;
  top: 30px;
}
.s-alert-top-right {
  bottom: auto;
  left: auto;
  right: 30px;
  top: 30px;
}
.s-alert-bottom-right {
  /*default*/
  bottom: 30px;
  left: auto;
  right: 30px;
  top: auto;
}
.s-alert-bottom {
  bottom: 0;
  left: 0;
  max-width: 100%;
  right: 0;
  top: auto;
  width: 100%;
}
.s-alert-top {
  bottom: auto;
  left: 0;
  max-width: 100%;
  right: 0;
  top: 0;
  width: 100%;
}

/* conditions */

.s-alert-info {
}
.s-alert-success {
}
.s-alert-warning {
}
.s-alert-error {
}

[class^="s-alert-effect-"].s-alert-hide,
[class*=" s-alert-effect-"].s-alert-hide {
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

/* height measurement helper */
.s-alert-box-height {
  position: fixed;
  visibility: hidden;
}
