$sidebar-logo-size: rem-calc(80);
$sidebar-gutter: rem-calc(30);
$searchbox-icon-size: rem-calc(32);
$searchbox-background: color(primary);
$searchbox-color: color(white);
$recipient-name-fontsize: rem-calc(14);
$recipient-icon-size: rem-calc(32);
$recipient-active-background: color(primary);
$recipient-active-color: color(white);
$pagination-item-size: rem-calc(26);

.SearchBox {
  background: $searchbox-background;
  color: $searchbox-color;
  padding: $sidebar-gutter;

  &-Wrapper {
    padding-left: calc($searchbox-icon-size + $global-padding / 2);
    position: relative;
  }

  &-Field {
    @include form-field(0);
  }

  &-Input {
    @include form-element(color(white));

    &:focus,
    &.has-value {
      ~ label {
        @include position(0, auto, 0, 0);
        @include font($label-font-size, 1);
      }
    }
  }

  &-Label {
    @include form-label($searchbox-color);
  }

  &-Bar {
    @include form-bar($searchbox-color);
  }

  &-Reset {
    color: color(white);
    opacity: 0.6;
    transition: all $global-transition-duration $global-transition-function;
    outline: none;
    @include position($label-font-size + rem-calc(2), 0, auto, auto);

    .icon {
      @include icon($input-font-size);
    }

    &:hover,
    &:focus {
      transform: scale(1.2);
      opacity: 1;
    }
  }

  &-Submit {
    color: inherit;

    .icon {
      @include icon($searchbox-icon-size, $input-height - $label-font-size);
      @include position($label-font-size + $input-border-size, auto, auto, 0);
    }
  }

  &-AddRecipient {
    @include button(true, color(white), color(grey, light), color(primary), solid);
    font-size: 0.9em;
  }
}

.Search {
  overflow: hidden;
  background-color: color(white);
  &-Header {
    height: rem-calc(87);
    margin: rem-calc(5);
    position: relative;

    img {
      display: block;
      width: $sidebar-logo-size;
      @include absolute-center;
    }
  }

  &-Title {
    padding: $sidebar-gutter;
    position: relative;
    @include font(rem-calc(20), 1.2, bold);
  }

  &-Count {
    color: color(primary);
    @include font(rem-calc(20), 1.2, normal, alt);
  }

  &-Loader {
    opacity: 0;
    transition: opacity $global-transition-duration $global-transition-function;
    @include position(auto, 4px, 0, auto);

    &.loading {
      opacity: 1;
    }
  }

  &-Display {
    text-align: center;
    margin-top: $global-padding;

    &-Button {
      outline: none;
      @include button(false, color(primary), color(primary), color(white), solid);

      &--mask {
        @include button(false, color(primary), color(primary), color(white), hollow);
      }
    }
  }
}

.SearchResults {
  //background-color: color(white);
  &-Items {
    @extend %nobullet;
    border-top: 1px solid color(grey, light);
    margin-bottom: 0;
  }

  &-Item {
    border-bottom: 1px solid color(grey, light);
  }

  &-NoResults,
  &-Error {
    border-top: 1px solid color(grey, light);
    padding: calc($sidebar-gutter / 2) $sidebar-gutter;
  }

  &-Error {
    color: color(alert);
  }

  &-Pagination {
    @extend %nobullet;
    margin-bottom: 0;
    padding: calc($sidebar-gutter / 2) $sidebar-gutter;
    overflow: hidden;

    &-Item {
      display: inline-block;
      text-align: center;
      @include auto-width(5);
      @include font(rem-calc(16), $pagination-item-size, bold, alt);

      button {
        color: color(grey, base);
        cursor: pointer;

        &:disabled {
          color: color(grey, light);
        }
      }

      .icon {
        @include icon($pagination-item-size, $pagination-item-size);
      }
    }
  }
}

.Recipient {
  &-Link {
    color: $body-font-color;
    display: block;
    padding: $sidebar-gutter;
    transition: background $global-transition-duration $global-transition-function;

    &:hover,
    &:focus {
      background: color(grey, light);
      color: inherit;

      .Recipient-Chevron {
        margin-right: -10px;
        opacity: 0.6;
      }
    }

    &.selected {
      background: $recipient-active-background;
      color: $recipient-active-color;
    }
  }

  &-Wrapper {
    padding-left: ($recipient-icon-size + rem-calc(10));
    padding-right: rem-calc(20);
    position: relative;
  }

  &-Name {
    color: color(black);
    margin-bottom: 0;
    @include font(rem-calc(16), 1.2, bold);

    .selected & {
      color: inherit;
    }
  }

  &-SearchCount {
    color: color(secondary);
    @include font(rem-calc(13), rem-calc(20), bold);

    .selected & {
      color: inherit;
    }
  }

  &-Icon {
    color: color(primary);
    display: inline-block;
    @include position(rem-calc(2), auto, auto, 0);
    @include icon($recipient-icon-size);

    .unactivated & {
      color: color(grey, base);
    }

    .selected & {
      color: inherit;
    }
  }

  &-Chevron {
    color: color(grey, medium);
    right: -10px;
    transition: all $global-transition-duration $global-transition-function;
    @include vertical-center;
    @include icon(rem-calc(24), $recipient-icon-size);

    .selected & {
      color: inherit;
    }
  }
}

.Recipient-Status {
  @include font(rem-calc(13), rem-calc(20), bold);

  span {
    font-weight: normal;
  }

  strong {
    color: color(alert);

    .selected & {
      color: inherit;
    }
  }
}
