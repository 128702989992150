$profilSearch-item-background: color(grey, light);
$profilSearch-item-padding: $global-padding;
$profilSearch-item-margin: $global-padding;

$offer-padding: rem-calc(20);

.ProfileSearch {
  &-Items {
    @extend %nobullet;
  }

  &-Item {
    background: $profilSearch-item-background;
    box-shadow: inset 0 1px 2px color(background);
    padding: $profilSearch-item-padding $form-fieldset-padding-x;
    position: relative;
    margin-left: -$form-fieldset-padding-x;
    margin-right: -$form-fieldset-padding-x;
    margin-bottom: $profilSearch-item-margin;

    &-Title {
      color: color(grey, medium);
      margin-bottom: rem-calc(5);
      @include font(rem-calc(14), rem-calc(18), bold, default, true);
    }

    &-Consult {
      color: color(black);
      cursor: pointer;
      transition: color $global-transition-duration $global-transition-function;
      @include font(100%, rem-calc(28), bold);

      .icon {
        @include icon(rem-calc(24), rem-calc(28), right);
      }

      &:hover,
      &:focus {
        color: color(primary);
        outline: none;
      }

      &:disabled {
        color: color(grey, base);
        cursor: default;
      }
    }

    &-Delete {
      color: color(grey, medium);
      cursor: pointer;
      @include font(100%, rem-calc(30));
      @include position(-rem-calc(30), 0, auto, auto);
      .icon {
        @include icon(rem-calc(18), rem-calc(30), right);
      }
    }
  }

  &-Tags {
    color: color(black);
  }

  &-Offers {
    &-Stats {
      margin-top: $global-margin;
      margin-bottom: $global-margin;
      @include grid-row($behavior: nest);
    }
    &-Stat {
      @include grid-column(4);

      .stat {
        display: block;
        margin-right: calc($global-padding / 2);
      }
      .label {
        clear: both;
        display: block;
      }
    }
  }

  &-AddItem {
    color: color(grey, dark);
    cursor: pointer;
    display: inline-block;
    margin-bottom: $form-spacing;
    transition: color $global-transition-duration $global-transition-function;
    @include font(100%, rem-calc(28), bold);

    .icon {
      @include icon(rem-calc(16), rem-calc(28), left);
    }

    &:hover,
    &:focus {
      color: color(black);
      outline: none;
    }
  }
}

.Offers {
  &-Items {
    @extend %nobullet;
  }

  &-Item {
    border-top: 1px solid color(grey, light);

    &:last-child {
      border-bottom: none;
    }
  }
}

.Offer {
  position: relative;
  padding: $offer-padding 0 $offer-padding 0;

  &-Header {
    margin-bottom: $global-padding;
  }

  &-Label {
    background: color(grey, light);
    color: color(grey, dark);
    margin-right: calc($global-padding / 2);
    margin-bottom: calc($global-padding / 4);
    @include label;
    @include font(rem-calc(13), rem-calc(20), bold);
  }

  &-Date {
    float: right;
    margin-bottom: calc($global-padding / 4);
    padding: $label-padding;
    padding-left: 0;
    padding-right: 0;
    @include font(rem-calc(12), rem-calc(20));
  }

  &-Title {
    color: color(black);
    @include font(rem-calc(18), $font-weight: bold, $uppercase: true);
  }

  &-Description {
    color: color(black);
    margin-bottom: $global-padding;
    @include font(rem-calc(14));
  }

  &-Link {
    @include button;
    @include font(rem-calc(16), rem-calc(36));
    padding: 0 rem-calc(18);

    .icon {
      @include icon(rem-calc(18), rem-calc(18));
    }
  }

  &-LinkDisabled {
    @include font(rem-calc(16), rem-calc(36));
    color: $primary-color;
    padding: 0;
  }
}
