%posrel {
  position: relative;
  z-index: 10;
}

%spacereset {
  margin: 0;
  padding: 0;
}

%dimreset {
  height: auto;
  width: auto;
}

%nobullet {
  list-style: none;
  margin-#{$global-left}: 0;
}

%pseudoBlock {
  content: "";
  display: block;
  position: relative;
}

%truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%uppercase {
  text-transform: uppercase;
}

@mixin position($top: 0, $right: auto, $bottom: auto, $left: 0) {
  bottom: $bottom;
  left: $left;
  position: absolute;
  right: $right;
  top: $top;
}

@mixin section(
  $background-color: transparent,
  $color: inherit,
  $padding: $section-default-padding 0
) {
  background-color: $background-color;
  color: $color;
  position: relative;
  padding: $padding;
  overflow: hidden;
}

@mixin connected-title($fontsize, $font-alt: true) {
  color: color(palette, lightblue);
  display: block;
  margin-bottom: 0.2em;
  @if $font-alt {
    @include font($fontsize, 1, normal, alt, true, 0.3em);
  } @else {
    @include font($fontsize, 1, normal, default, true, 0.3em);
  }
}

@mixin section-title() {
  margin-bottom: rem-calc(50);
  @include font($font-weight: bold);

  .connected {
    @include connected-title(rem-calc(75));
  }
}

@mixin section-list() {
  @extend %nobullet;

  .item {
    margin-bottom: rem-calc(30);
    overflow: hidden;

    &-bullet {
      display: block;
      height: rem-calc(4);
      width: rem-calc(30);
      margin-bottom: rem-calc(20);
      background-color: color(palette, green);
    }
    &:nth-child(2) {
      .item-bullet {
        background-color: color(palette, yellow);
      }
    }
    &:nth-child(3) {
      .item-bullet {
        background-color: color(palette, blue);
      }
    }
    &:nth-child(4) {
      .item-bullet {
        background-color: color(palette, magenta);
      }
    }

    &-title {
      margin-bottom: 0;
      @include font(rem-calc(34), 1.2, normal, alt, true);

      .connected {
        @include connected-title(rem-calc(16), false);
      }
    }
  }
}

@mixin half-background(
  $color: color(white),
  $offset: null,
  $breakpoint: large
) {
  position: relative;

  &:before {
    @extend %pseudoBlock;
  }

  @include breakpoint($breakpoint) {
    &:before {
      background-color: $color;
      height: 90%;
      box-shadow: 20px 0 40px rgba(color(black), 0.1);
      @if $offset != null {
        width: calc(50% + #{$offset});
      } @else {
        width: 50vw;
      }
      @include position(0, auto, 0, 0);
    }
  }
}
