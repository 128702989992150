$font-family-default: "Raleway", "Helvetica Neue", Helvetica, Roboto, Arial,
  sans-serif;
$font-family-alt: "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial,
  sans-serif;

@mixin font(
  $font-size: 1rem,
  $line-height: $global-lineheight,
  $font-weight: normal,
  $font-family: default,
  $uppercase: false,
  $letter-spacing: normal,
  $font-style: normal
) {
  @if $font-family == alt {
    font-family: $font-family-alt;
  } @else {
    font-family: $font-family-default;
  }

  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;

  @if $font-weight == "bold" {
    font-weight: 700;
  } @else {
    font-weight: 400;
  }

  @if $uppercase {
    text-transform: uppercase;
  } @else {
    text-transform: none;
  }

  @if $font-style == "italic" {
    font-style: italic;
  } @else {
    font-style: normal;
  }
}

@mixin icon($font-size: 1em, $size: 24px, $align: none) {
  display: inline-block;
  float: $align;
  font-size: $font-size;
  height: $size;
  line-height: $size;
  text-align: center;
  vertical-align: middle;
  width: $size;
  @content;
}

%material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

%heading-1 {
  @include font(rem-calc(20), rem-calc(30), bold, default, true);
}

%heading-2 {
  @include font(rem-calc(20), rem-calc(30), bold, default, false);
}
