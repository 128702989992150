$subscription-color: color(secondary);

.SubscriptionOffer {
  &-Label {
    cursor: pointer;
    display: block;
    text-align: center;
    transition: all $global-transition-duration $global-transition-function;
    @include card-container;
    @include card-section;
    @include font(rem-calc(14), rem-calc(20));
  }

  &-Title {
    color: $subscription-color;
    border-bottom: 1px solid color(background);
    padding-bottom: rem-calc(10);
    @include font(rem-calc(16), 1, bold);
  }

  &-Duration {
    color: $subscription-color;
    margin-bottom: calc($global-padding / 2);
    @include font(rem-calc(16), rem-calc(22), bold, alt, true);

    .icon {
      @include icon(rem-calc(44), rem-calc(50)) {
        color: color(grey, dark);
      }
    }
  }

  &-Date {
    display: block;
    text-align: center;
    @include font(rem-calc(14), rem-calc(20), bold, alt);
  }

  input[type="radio"],
  input[type="checkbox"] {
    @include element-invisible;

    &:checked {
      ~ .SubscriptionOffer-Label {
        background: color(primary);
        color: color(white);

        .SubscriptionOffer-Title {
          border-color: currentColor;
          color: currentColor;
        }
        .SubscriptionOffer-Duration {
          color: currentColor;

          .icon {
            color: currentColor;
          }
        }
      }
    }
  }
}
