@mixin fieldset-reset {
  border: 0;
  margin: 0;
  min-width: 0;

  legend {
    display: table;
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;

    + * {
      clear: both;
    }
  }
}

@mixin form-fieldset {
  border: none;
  margin-bottom: $form-fieldset-padding-y;
  padding: $form-fieldset-padding-y $form-fieldset-padding-x;
  padding-bottom: 0;
  position: relative;
  @include fieldset-reset;

  &:not(.noborder) {
    &:after {
      @extend %pseudoBlock;
      background: color(grey, light);
      height: rem-calc(1);
      width: 100%;
    }
  }

  legend {
    @extend %heading-2;
    margin-top: -0.2em;
    margin-bottom: $global-padding;
  }
}
