$modal-size: rem-calc(600);
$modal-background: color(white);
$modal-padding: calc($form-fieldset-padding-x / 2);

/* Remove scroll on the body when react-modal is open */
.ReactModal__Body--open {
  overflow: hidden;
}

.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(color(black), 0.9);
  z-index: 990;
}

.Modal {
  background: $modal-background;

  max-height: 80vh;
  height: 100%;
  width: $modal-size;
  overflow: hidden;
  outline: none;
  z-index: 999;
  @include absolute-center;

  &-Wrapper {
    max-height: 100%;
    overflow-y: auto;
    padding: rem-calc(36);
  }

  &-Title {
    @include font($font-size: rem-calc(16), $font-weight: bold);

    &--primary {
      color: color(primary);
    }
  }

  &-CloseButton {
    cursor: pointer;
    color: color(grey, medium);
    transition: color $global-transition-duration $global-transition-function;

    @include position(0, 0, auto, auto);

    .icon {
      @include icon(rem-calc(24), rem-calc(42));
    }

    &:hover,
    &:focus {
      color: color(grey, dark);
    }
  }

  &-Footer {
    background: color(primary);
    color: color(white);
    text-align: right;
    padding: $global-padding;

    button {
      border-color: color(white);
    }
  }
}
