$button-size: rem-calc(16);
$button-height: rem-calc(40);
$button-border-size: rem-calc(1);
$button-padding: 2em;
$button-margin: 0;
$button-fill: hollow;
$button-background: $primary-color;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$button-color: $white;
$button-color-alt: $black;
$button-radius: $button-height;
$button-hollow-border-width: 1px;
$button-palette: $foundation-palette;
$button-opacity-disabled: 0.25;
$button-background-hover-lightness: -20%;
$button-hollow-hover-lightness: -50%;

// Internal: flip from margin-right to margin-left for defaults
@if $global-text-direction == "rtl" {
  $button-margin: 0 0 $global-margin $global-margin !default;
}

$button-transition: background-color 0.25s ease-out, color 0.25s ease-out !default;

// TODO: Document button-base() mixin
@mixin button-base {
  @include disable-mouse-outline;
  display: inline-block;
  vertical-align: middle;
  margin: $button-margin;
  padding: 0 $button-padding;
  -webkit-appearance: none;
  border: $button-border-size solid transparent;
  border-radius: $button-radius;
  transition: $button-transition;
  line-height: 1;
  text-align: center;
  cursor: pointer;

  @include font($button-size, $button-height, bold);
}

/// Expands a button to make it full-width.
/// @param {Boolean} $expand [true] - Set to `true` to enable the expand behavior. Set to `false` to reverse this behavior.
@mixin button-expand($expand: true) {
  @if $expand {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  } @else {
    display: inline-block;
    width: auto;
    margin: $button-margin;
  }
}

/// Sets the visual style of a button.
/// @param {Color} $background [$button-background] - Background color of the button.
/// @param {Color} $background-hover [$button-background-hover] - Background color of the button on hover. Set to `auto` to have the mixin automatically generate a hover color.
/// @param {Color} $color [$button-color] - Text color of the button. Set to `auto` to automatically generate a color based on the background color.
@mixin button-style(
  $background: $button-background,
  $background-hover: $button-background-hover,
  $color: $button-color,
  $background-hover-lightness: $button-background-hover-lightness
) {
  @if $color == auto {
    $color: color-pick-contrast(
      $background,
      ($button-color, $button-color-alt)
    );
  }

  @if $background-hover == auto {
    $background-hover: scale-color(
      $background,
      $lightness: $background-hover-lightness
    );
  }

  background-color: $background;
  color: $color;

  &:hover,
  &:focus {
    background-color: $background-hover;
    color: $color;
  }
}

/// Removes background fill on hover and focus for hollow buttons.

@mixin button-hollow-style(
  $color: $primary-color,
  $color-hover: scale-color($color, $lightness: $button-hollow-hover-lightness)
) {
  &,
  &:active,
  &:visited,
  &:focus {
    background-color: transparent;
    border: $button-hollow-border-width solid $color;
    color: $color;
  }

  &:hover {
    background: $color;
    color: $color-hover;
  }
}

/// Adds disabled styles to a button by fading the element, reseting the cursor, and disabling pointer events.
/// @param [Color] $background [$primary-color] - Background color of the disabled button.
/// @param [Color] $color [$button-color] - Text color of the disabled button. Set to `auto` to have the mixin automatically generate a color based on the background color.
@mixin button-disabled($background: $primary-color, $color: $button-color) {
  @if $color == auto {
    $color: color-pick-contrast(
      $background,
      ($button-color, $button-color-alt)
    );
  }

  opacity: $button-opacity-disabled;
  cursor: not-allowed;

  &,
  &:hover,
  &:focus {
    background-color: $background;
    color: $color;
  }
}

@mixin button(
  $expand: false,
  $background: $button-background,
  $background-hover: $button-background-hover,
  $color: $button-color,
  $style: $button-fill
) {
  @include button-base;

  @if $style == solid {
    @include button-style($background, $background-hover, $color);
  } @else if $style == hollow {
    @include button-hollow-style($background, $color);
  }

  @if $expand {
    @include button-expand;
  }
}

%button-submit {
  @include button(
    false,
    color(primary),
    darken(color(primary), 10%),
    color(white),
    solid
  );

  &:disabled {
    @include button-disabled(color(primary), color(white));
  }
}
