$header-size: rem-calc(200);
$header-offset: rem-calc(60);
$sidebar-size: rem-calc(310);
$wrapper-size: rem-calc(630) + 2 * $global-margin;
$global-zindex: 10;

@mixin reinitialize {
  bottom: auto;
  height: auto;
  left: auto;
  margin: 0;
  padding: 0;
  position: relative;
  right: auto;
  top: auto;
  width: auto;
}

.App {
  background-color: color(background);
  height: 100%;
  margin-left: $sidebar-size;
  min-height: rem-calc(1050);

  // positionnement pour navigateurs ne supportant pas grid-layout
  padding: $header-offset 0 $header-offset 0;

  &-Header {
    height: $header-size;
    width: calc(100% - #{$sidebar-size});
    z-index: $global-zindex;
    @include position(0, 0, auto, auto);
  }

  &-Sidebar {
    height: 100%;
    width: $sidebar-size;
    @include position(0, auto, auto, 0);
    //overflow: hidden;
  }

  &-Wrapper {
    margin: 0 auto;
    position: relative;
    width: $wrapper-size;
    z-index: $global-zindex + 1;
  }

  // positionnement pour navigateurs supportant grid-layout
  @supports (display: grid) {
    display: grid;
    grid-template-columns: $sidebar-size auto $wrapper-size auto;
    grid-template-rows: rem-calc(60) $header-size auto rem-calc(30);
    min-height: 100vh;

    // réinitialise le comportament
    @include reinitialize;

    &-Header {
      @include reinitialize;
    }

    &-Sidebar {
      @include reinitialize;
    }

    &-Wrapper {
      @include reinitialize;
    }

    // disposition avec grid-layout
    &-Header {
      grid-column: 2 / 5;
      grid-row: 1 / 3;
    }

    &-Sidebar {
      grid-column: 1 / 2;
      grid-row: 1 / -1;
    }

    &-Wrapper {
      grid-column: 3 / 4;
      grid-row: 2 / 4;
    }
  }

  &-Header {
    background-image: url("./images/background.jpg");
    background-position: top center;
    background-size: cover;
    color: color(white);
    padding: $global-padding;
    text-align: right;

    @include breakpoint(retina) {
      background-image: url("./images/background_@2x.jpg");
    }

    &-Links {
      @include navbar;
    }
  }

  &-Wrapper {
    padding: 0 $global-margin;
  }

  &-Sidebar {
    background: color(white);
  }
}
