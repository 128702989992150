.RecipientAdd {
  &-Title {
    @extend %heading-1;
    color: color(white);
    margin-bottom: 0;
  }

  &-Form {
    background: color(white);
  }
}
