$login-card-size: rem-calc(292);
$login-logo-size: rem-calc(75);

.Login {
  background-color: color(primary);
  background-image: url("./images/background-login.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  height: 100vh;

  @include breakpoint(retina) {
    background-image: url("./images/background-login_@2x.jpg");
    background-size: 1920px 427px;
  }

  a {
    color: inherit;
    display: inline-block;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: rem-calc(1);
      margin-top: rem-calc(2);
      background-color: currentColor;
      opacity: 0.2;
      transition: opacity $global-transition-duration
        $global-transition-function;
    }

    &:hover,
    &:focus {
      &:after {
        opacity: 1;
      }
    }
  }

  &-Wrapper {
    width: $login-card-size;
    background: color(white);
    padding: rem-calc(30);
    box-shadow: 1px 1px 4px rgba(color(black), 0.5);
    @include absolute-center;
  }

  &-Header {
    text-align: center;
    @include card-section;
  }

  &-Logo {
    width: $login-logo-size;
  }

  &-Content {
    @include card-section;
  }

  &-Message {
    color: color(alert);
    margin-bottom: $global-padding;

    @include font(rem-calc(14), 1.2, $font-weight: bold);
  }

  &-Form {
    &-Field {
      @include form-field;
    }

    &-Submit {
      @include button(
        true,
        color(primary),
        color(primary),
        color(white),
        hollow
      );
    }
  }

  &-ForgotPassword {
    margin-top: $global-padding;
    text-align: center;
    @include font(rem-calc(14), 1.2);
  }

  &-Footer {
    color: color(white);
    text-align: center;
    width: 100%;
    padding-bottom: $global-padding;
    @include font(rem-calc(12));
    @include position(auto, auto, 0, 0);

    img {
      display: inline-block;
      margin-bottom: $global-padding;
      height: 50px;
      width: auto;
    }
  }
}
