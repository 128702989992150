$row: "row";
$column: "column";
$column-row: "column-row";
$gutter: "gutter";
$push: "push";
$pull: "pull";
$center: "centered";
$uncenter: "uncentered";
$collapse: "collapse";
$uncollapse: "uncollapse";
$offset: "offset";
$end: "end";
$expanded: "expanded";
$block: "block";

@mixin form-row {
  @include grid-row($behavior: nest);
}

@mixin form-column {
  @include grid-column;

  &:last-child:not(:first-child) {
    float: left;
  }

  @include -zf-each-breakpoint {
    @for $i from 1 through $grid-column-count {
      // Column width
      &.#{$-zf-size}-#{$i} {
        @include grid-col-size($i);
      }

      // Source ordering
      @if $i < $grid-column-count {
        &.#{$-zf-size}-#{$push}-#{$i} {
          @include grid-col-pos($i);
        }

        .#{$-zf-size}-#{$pull}-#{$i} {
          @include grid-col-pos(-$i);
        }
      }

      // Offsets
      $o: $i - 1;

      &.#{$-zf-size}-#{$offset}-#{$o} {
        @include grid-col-off($o);
      }
    }

    // Positioning
    &.#{$-zf-size}-#{$center} {
      @include grid-col-pos(center);
    }

    // Gutter adjustment
    &.#{$-zf-size}-#{$uncenter},
    &.#{$-zf-size}-#{$push}-0,
    &.#{$-zf-size}-#{$pull}-0 {
      @include grid-col-unpos;
    }
  }
}
