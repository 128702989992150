.Error {
  background-color: color(background);
  min-height: 100vh;
  text-align: center;

  svg {
    width: 70vw;
    max-width: 600px;
    max-height: 80vh;
  }

  &-Link {
    @include button(
      false,
      color(primary),
      darken(color(primary), 10%),
      color(white),
      solid
    );
  }
}
