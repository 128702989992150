$form-spacing: rem-calc(30);
$input-color: color(black);
$input-placeholder-color: color(grey, dark);
$input-font-size: rem-calc(14);
$label-font-size: rem-calc(10);
$input-height: rem-calc(40);
$input-border-size: rem-calc(2);
$input-border-color: color(grey, medium);
$input-border-focus-color: color(primary);
$input-cursor-disabled: not-allowed;
$input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
$input-number-spinners: true;
$form-button-radius: $global-radius;
$form-fieldset-padding-x: rem-calc(45);
$form-fieldset-padding-y: rem-calc(30);

$all-types: text password date datetime datetime-local month week email number
  search tel time url color;

@import "text";
@import "fieldset";
@import "grid";

@mixin form-field($margin: $form-spacing) {
  position: relative;
  padding-top: $label-font-size;
  margin-bottom: $margin;
}

.form {
  &-fieldset {
    @include form-fieldset;
  }

  &-row {
    @include form-row;
    & + & {
      margin-top: $form-fieldset-padding-y;
    }
  }

  &-column {
    @include form-column;
  }

  &-field {
    @include form-field;

    // Text inputs
    #{text-inputs()},
    textarea {
      @include form-element;
      appearance: none;

      &:focus,
      &.has-value {
        outline: none;

        ~ .form-label {
          @include form-label-focus;
        }

        ~ .form-bar {
          @include form-bar-focus;
        }
      }
    }

    // Text areas
    textarea {
      max-width: 100%;

      &[rows] {
        height: auto;
      }
    }
  }

  &-label {
    @include form-label;
  }

  &-bar {
    @include form-bar;
  }

  &-footer {
    padding: $form-fieldset-padding-y $form-fieldset-padding-x;
  }

  &-submit {
    @extend %button-submit;
  }
}
