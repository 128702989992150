$backlink-font-size: rem-calc(14);
$backlink-icon-size: rem-calc(20);
$backlink-icon-height: rem-calc(22);

.RecipientEdit {
  &-BackLink {
    color: color(white);
    display: block;
    position: fixed;
    top: $global-padding;
    left: $sidebar-size + $global-padding;
    transition: color $global-transition-duration $global-transition-function;
    @include font($backlink-font-size, $backlink-icon-height, bold);

    .icon {
      transition: transform $global-transition-duration
        $global-transition-function;
      @include icon($backlink-icon-size, $backlink-icon-height, left);
    }

    &:hover,
    &:focus {
      .icon {
        transform: translateX(-10px);
      }
    }
  }

  &-Offer {
    margin-top: $global-margin;

    &-Header {
      @include flex-align(justify, middle);
      //color: color(white);
      @include flex;
    }

    &-Title {
      @extend %heading-1;
      margin-bottom: 0;
    }

    &-Actions {
      margin-left: auto;
      @include navbar(rem-calc(14), bold);
    }

    &-Form {
      background: color(white);
      position: relative;
    }

    &-Paused {
      background: rgba(color(primary), 0.9);
      color: color(white);
      height: 100%;
      width: 100%;
      @include position;

      &-Wrapper {
        text-align: center;
        width: rem-calc(300);
        @include absolute-center;
        @include font($font-weight: bold);
      }

      &-Button {
        @include button(
          false,
          color(white),
          color(white),
          color(primary),
          hollow
        );
      }
    }

    &-Disabled {
      background: rgba(color(grey, dark), 0.9);
      color: color(white);
      height: 100%;
      width: 100%;
      @include position;

      &-Wrapper {
        text-align: center;
        width: rem-calc(300);
        @include absolute-center;
        @include font($font-weight: bold);
      }

      &-Button {
        @include button(false, color(white), darken(color(white), 5%));
      }
    }
  }

  &-Infos {
    &-Header {
      color: color(white);
    }

    &-Title {
      @extend %heading-1, %truncate;
      margin-bottom: 0;
    }

    &-Banner {
      background-color: color(alert);
      color: color(white);
      text-align: center;
      padding: $form-fieldset-padding-x;

      @include font($font-weight: bold);
    }

    &-Form {
      background: color(white);
    }
  }
}
