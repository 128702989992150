@mixin form-element($color: $input-color, $fixedHeight: true) {
  $height: $input-height - $label-font-size;

  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  color: $color;

  @if $fixedHeight {
    height: $height;
  } @else {
    min-height: $height;
  }

  @include font($input-font-size, $height);

  // Focus state
  &:focus {
    outline: none;
  }
}

@mixin form-label($color: $input-placeholder-color) {
  $height: $input-height - $label-font-size;
  pointer-events: none;
  z-index: 1;
  color: $color;
  transition: all calc($global-transition-duration / 2) ease;
  @include font($input-font-size, $height);
  @include position($label-font-size, auto, auto, 0);
}

@mixin form-label-focus($color: $input-border-focus-color) {
  color: $color;

  @include position(0, auto, 0, 0);
  @include font($label-font-size, 1);
}

@mixin form-bar(
  $border-color: $input-border-color,
  $border-focus-color: $input-border-focus-color
) {
  position: relative;
  border-bottom: $input-border-size solid $border-color;
  transition: border-color $global-transition-duration
    $global-transition-function;
  display: block;

  &:before {
    content: "";
    height: $input-border-size;
    width: 0;
    background: $border-focus-color;
    transition: left $global-transition-duration $global-transition-function,
      width $global-transition-duration $global-transition-function;
    z-index: 2;

    @include position(auto, auto, -$input-border-size, 50%);
  }
}

@mixin form-bar-focus {
  border-color: transparent;

  &:before {
    width: 100%;
    left: 0;
  }
}

@mixin form-text {
  // Text inputs
  #{text-inputs()},
  textarea {
    @include form-element;
    appearance: none;
  }

  // Text areas
  textarea {
    max-width: 100%;

    &[rows] {
      height: auto;
    }
  }

  input,
  textarea {
    // Placeholder text
    &::placeholder {
      color: $input-placeholder-color;
    }

    // Disabled/readonly state
    &:disabled,
    &[readonly] {
      background-color: $input-background-disabled;
      cursor: $input-cursor-disabled;
    }
  }

  // Reset styles on button-like inputs
  [type="submit"],
  [type="button"] {
    appearance: none;
    border-radius: $form-button-radius;
  }

  // Reset Normalize setting content-box to search elements
  input[type="search"] {
    // sass-lint:disable-line no-qualifying-elements
    box-sizing: border-box;
  }

  // Number input styles
  [type="number"] {
    @if not $input-number-spinners {
      -moz-appearance: textfield; // sass-lint:disable-line no-vendor-prefix

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefix
        margin: 0;
      }
    }
  }
}
